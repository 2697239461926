<!-- https://github.com/greyby/vue-spinner/blob/master/src/MoonLoader.vue -->
<template>
  <div
    v-show="loading"
    class="v-spinner"
  >
    <div
      class="v-moon v-moon1"
      :style="spinnerStyle"
    >
      <div
        class="v-moon v-moon2"
        :style="[spinnerMoonStyle,animationStyle2]"
      ></div>
      <div
        class="v-moon v-moon3"
        :style="[spinnerStyle,animationStyle3]"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoonLoader',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: String,
      default: '50px'
    },
    margin: {
      type: String,
      default: '0px'
    },
    radius: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      spinnerStyle: {
        height: this.size,
        width: this.size,
        borderRadius: this.radius
      }
    };
  },
  computed: {
    moonSize() {
      return parseFloat(this.size) / 7;
    },
    spinnerMoonStyle() {
      return {
        height: `${this.moonSize}px`,
        width: `${this.moonSize}px`,
        borderRadius: this.radius
      };
    },
    animationStyle2() {
      return {
        top: `${parseFloat(this.size) / 2 - this.moonSize / 2}px`,
        backgroundColor: this.color
      };
    },
    animationStyle3() {
      return {
        border: `${this.moonSize}px solid ${this.color}`
      };
    }
  }
};
</script>

<style lang="scss">
.v-spinner .v-moon1 {
  animation: v-moonStretchDelay 0.6s 0s infinite linear;
  animation-fill-mode: forwards;
  position: relative;
}
.v-spinner .v-moon2 {
  animation: v-moonStretchDelay 0.6s 0s infinite linear;
  animation-fill-mode: forwards;
  opacity: 1;
  position: absolute;
}
.v-spinner .v-moon3 {
  opacity: 0.2;
}
@-webkit-keyframes v-moonStretchDelay {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes v-moonStretchDelay {
  100% {
    transform: rotate(360deg);
  }
}
</style>
