<template>
  <!-- eslint-disable max-len -->
  <div>
    <svg style="position: absolute; top: -9999px;">
      <symbol
        id="icon_arrow-up"
        viewBox="0 0 500 500"
      >
        <path d="M249.76,77.47,378.84,206.54l-27,27L269,150.67V422.33H230.48V150.68l-82.83,82.85-27-27Z" />
      </symbol>
      <symbol
        id="icon_arrow-left"
        viewBox="0 0 500 500"
      >
        <path d="M77.57,251.59,206.64,122.5l27,27-82.87,82.85H422.43v38.53H150.78l82.85,82.83-27,27Z" />
      </symbol>

      <symbol
        id="icon_arrow-right"
        viewBox="0 0 500 500"
      >
        <path d="M422.43,251.59,293.36,380.67l-27-27,82.87-82.85H77.57V232.31H349.22l-82.85-82.83,27-27Z" />
      </symbol>
      <symbol
        id="icon_reset"
        viewBox="0 0 24 24"
      >
        <path
          d="M5.40001 10.7H3.90001C3.20001 10.7 2.90001 11.4 3.10001 11.8L5.80001 16.8C6.10001 17.2 6.70001 17.2 7.10001 16.6L9.80001 11.8C9.90001 11.2 9.70001 10.7 9.00001 10.7H7.60001C8.10001 8 10.4 6.2 13.1 6.2C16.2 6.2 18.7 8.7 18.7 11.8C18.7 14.9 16.5 17.4 13.1 17.4V19.6C17.6 19.6 21 16.1 21 11.7C21 7.3 17.5 4 13.1 4C9.20001 4 5.90001 6.8 5.40001 10.7Z"
        />
      </symbol>
    </svg>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
export default {
  name: 'SvgSymbols'
};
</script>

<style lang="scss" scoped>

</style>
