<template>
  <span>{{ dots }}</span>
</template>

<script>
export default {
  data() {
    return {
      dots: '.',
      interval: null
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.dots.length < 3) {
        this.dots += '.';
      } else {
        this.dots = '';
      }
    }, 500);
  }
};
</script>

<style></style>
