<template>
  <div id="app">
    <Symbols />

    <TopBar />
    <div class="sb_input">
      <select id="" v-model="model" name="">
        <option v-for="m in models" :key="" :value="m">
          {{ m.name }}
        </option>
      </select>
      <form @submit.prevent="stylize">
        <div class="sb_input_prompt">
          <input
            id="prompt"
            v-model="prompt"
            type="text"
            name=""
            :disabled="loading"
          />
          <button
            :disabled="loading"
            type="button"
            class="button light"
            @click.prevent="inspire"
          >
            Inspiration
          </button>
        </div>
        <button type="submit" class="button">
          <span v-if="!loading">Stylize</span>
          <span v-if="loading"> Loading<DotLoader /> </span>
        </button>
      </form>
    </div>

    <div class="sb_container">
      <BackgroundGlobes />
      <div v-if="loading" class="sb_container_loading">
        <div class="sb_container_loading_inner">
          <LoadingSpinner />
        </div>
      </div>
      <div class="sb_container_left">
        <DragAnimation />
        <Viewer3D
          v-if="model"
          ref="viewer3D"
          :extra-x-offset="extraXOffset"
          :extra-y-offset="extraYOffset"
          :extra-z-offset="extraZOffset"
          :model="model"
          class="sb_container_3dviewer"
        />
      </div>
      <div class="sb_container_right">
        <img
          v-if="renderedImage"
          :src="renderedImage"
          alt=""
          class="sb_container_result"
        />
      </div>
    </div>

    <div class="sb_previews">
      <div class="sb_previews_inner">
        <h2 v-if="renderedImages.length">
          Your renders in this session:
        </h2>
        <div class="sb_previews_items">
          <img
            v-for="image in renderedImages"
            :key="image"
            :src="image"
            alt=""
            @click="setPreviewImage"
          />
        </div>
      </div>
    </div>
    <div v-if="env === 'development'">
      x
      <input v-model.number="extraXOffset" type="number" />
      y
      <input v-model.number="extraYOffset" type="number" />
      z
      <input v-model.number="extraZOffset" type="number" />
    </div>

    <div class="sb_footer"></div>
  </div>
</template>

<script>
import Viewer3D from '@/components/Viewer3D';
import DragAnimation from '@/components/DragAnimation';
import LoadingSpinner from '@/components/LoadingSpinner';
import TopBar from '@/components/TopBar';
import BackgroundGlobes from '@/components/BackgroundGlobes';
import DotLoader from '@/components/DotLoader';
import Symbols from '@/components/SvgSymbols';
import axios from 'axios';

const models = [
  {
    name: 'cottage',
    model: 'cottage.obj',
    scale: 0.7,
    yOffset: -1
  },
  {
    name: 'villa',
    model: 'house01/house.obj',
    scale: 0.5,
    yOffset: 1
  },
  {
    name: 'building',
    model: 'building.obj',
    scale: 1,
    yOffset: -1.5
  },
  {
    name: 'vogels',
    model: 'vogels.obj',
    scale: 0.0005,
    xOffset: -3,
    yOffset: -1,
    zOffset: 6,
    rotate: true
  }

  // {
  //   name: 'racecar',
  //   model: 'racecar.obj',
  //   scale: 3,
  //   offset: 0
  // }
  // {
  //   name: 'cap',
  //   model: 'cap.obj',
  //   scale: 0.02,
  //   offset: 0
  // }
];

export default {
  name: 'App',
  components: {
    DotLoader,
    Symbols,
    Viewer3D,
    DragAnimation,
    LoadingSpinner,
    TopBar,
    BackgroundGlobes
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      loading: false,
      error: '',
      models,
      model: null,
      prompt: process.env.VUE_APP_STARTING_PROMPT || '',
      renderedImage: null,
      modelName: null,
      renderedImages: [],
      extraXOffset: 0,
      extraYOffset: 0,
      extraZOffset: 0,
      env: process.env.NODE_ENV
    };
  },
  watch: {
    model(newVal, oldVal) {
      console.log(newVal.name, oldVal.name);
      this.prompt = this.prompt.replace(oldVal.name, newVal.name);
    }
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    console.log(process.env);
    this.model = this.models[0];

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('vogels');
    if (!myParam) {
      this.models.pop();
    }
    console.log(myParam);
  },
  methods: {
    inspire() {
      const modelName = this.model.name;
      const inspiration = [
        `Drone shot of traditional Mediterranean ${modelName}`,
        `Drone shot of ${modelName} in the woods Norway`,
        `3D render of ${modelName}, vray, 4k, raytracing`,
        `${modelName} during golden hour`,
        `Oak ${modelName} at night`,
        `${modelName} in mist`
      ];
      const newInspiration =
        inspiration[Math.floor(Math.random() * inspiration.length)];
      if (newInspiration === this.prompt) {
        this.inspire();
      } else {
        this.prompt = newInspiration;
      }
    },
    stylize() {
      this.renderImage();
    },
    setPreviewImage(e) {
      this.renderedImage = e.target.src;
    },
    convertImageUrlToBase64(imageUrl) {
      return axios
        .get(imageUrl, {
          responseType: 'arraybuffer'
        })
        .then(response => {
          console.log(response.data);
          const base64String = Buffer.from(response.data, 'binary').toString(
            'base64'
          );
          const res = `data:${response.headers['content-type']};base64,${base64String}`;
          console.log({
            res
          });
          return res;
        })
        .catch(error => {
          throw new Error('Failed to load image', error.message || error);
        });
    },
    async renderImage() {
      if (this.loading) return;
      this.error = null;
      const image = this.$refs.viewer3D.exportImage();
      const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/predictions`;
      const input = {
        image,
        prompt: `${this.prompt}, ${process.env.VUE_APP_PROMPT ||
          'profesional photography'}`,
        seed: this.seed
      };
      this.loading = true;
      try {
        const { data } = await axios.post(API_URL, input, {
          mode: 'no-cors'
        });
        console.log({
          data
        });
        this.renderedImage = data[1];
        // temporarily save the images to a base64 string - the image at the returned URL is hosted temporarily
        const base64 = await this.convertImageUrlToBase64(data[1]);
        this.renderedImages.unshift(base64);
      } catch (e) {
        this.error = e.response.data || e.message || e;
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/app.scss';

#app {
  width: 100%;
  min-height: 100vh;
  background-color: #222222;
  transition: background-color 0.3s ease;
  color: #fff;
  font-size: 1.2rem;
}

button {
  background: white;
  font-family: 'Inter', sans-serif;
  border-radius: 99px;
  padding: 12px 20px;
  font-size: 1.2rem;
  margin: 4px;
  background: linear-gradient(
    180deg,
    #0b48ff 0,
    #4a97ff 0.01%,
    #006dff 100%,
    #006dff 100%
  );
  transition: background-color 0.2s ease;
  color: #fff;
  border: 1px solid #549dff;

  &.light {
    background: white;
    color: black;
  }

  &:hover {
    border: 1px solid #549dff;
    background: linear-gradient(
      0deg,
      #0b48ff 0,
      #4a97ff 0.01%,
      #006dff 100%,
      #006dff 100%
    );
  }
}

.sb_input {
  margin: 0 auto;
  max-width: 1536px;
  padding: 2rem 1rem;
  @media screen and (min-width: 800px) {
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: 1536px) {
    padding: 2rem 0;
  }

  &_prompt {
    position: relative;
    input {
      padding-right: 130px;
      width: 100%;

      @media screen and (min-width: 1024px) {
        min-width: 500px;
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 6px;
      padding: 8px 20px;
      border: none;
    }
  }

  form {
    @media screen and (min-width: 800px) {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  label {
    display: none;
    padding: 0 0.5rem 0 0;

    @media screen and (min-width: 600px) {
      display: inline-block;
    }
  }
}

.sb_container {
  position: relative;
  margin: 0 auto;
  max-width: 1536px;
  @media screen and (min-width: 1023px) {
    display: flex;
  }

  &_loading {
    pointer-events: none;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (min-width: 1023px) {
      flex-direction: row;
      flex: 0 0 50%;
    }

    @media screen and (min-width: 1536px) {
      flex: 0 0 768px;
    }
    &_inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 50%;

      @media screen and (min-width: 1536px) {
        height: 100%;
        flex: 0 0 768px;
      }
    }
  }

  &_left {
    position: relative;
    z-index: 2;
    @media screen and (min-width: 1023px) {
      flex: 0 0 50%;
    }
    @media screen and (min-width: 1536px) {
      flex: 0 0 768px;
    }
  }
  &_right {
    position: relative;
    z-index: 2;
    aspect-ratio: 3/2;
    background: rgba(black, 0.5);
    backdrop-filter: blur(10px);
    @media screen and (min-width: 1023px) {
      flex: 0 0 50%;
    }
    @media screen and (min-width: 1536px) {
      flex: 0 0 768px;
      height: 512px;
    }
  }
  &_3dviewer {
    aspect-ratio: 3/2;
  }
  &_result {
    aspect-ratio: 3/2;
    width: 100%;
  }
}

.sb_previews {
  position: relative;
  padding: 4rem 1rem 1rem 1rem;
  @media screen and (min-width: 1536px) {
    padding: 4rem 0 1rem 0;
    margin: 0 auto;
    max-width: 1536px;
  }

  &_inner {
  }

  &_items {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (min-width: 1536px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  h2 {
    margin: 0 0 1rem 0;
    font-size: 1.4rem;
    text-align: center;
  }
  img {
    aspect-ratio: 3/2;
    opacity: 0.3;
    transition: opacity 0.2s ease;
    max-width: 100%;
    max-height: 100%;
    display: block;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.sb_footer {
  padding: 0 0 10vh 0;
}
</style>
