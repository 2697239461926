<template>
  <div class="sb_top-bar">
    <div class="sb_top-bar_inner">
      <div class="sb_top-bar_heading">
        <h1><b>AI</b> Architect Inspiration</h1>
      </div>
      <div class="sb_top-bar_logo">
        <Logo />
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
  name: 'TopBar',
  components: {
    Logo
  }
};
</script>

<style lang="scss">
.sb_top-bar {
  position: relative;
  width: 100%;
  top: 0;
  background: rgba(black, 0.1);
  backdrop-filter: blur(10px);
  z-index: 2;
  &_inner {
    margin: 0 auto;
    max-width: 1536px;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    @media screen and (min-width: 1536px) {
      padding: 2rem 0;
    }
  }

  &_logo {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }

  &_heading {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.4rem;
      font-weight: 700;

      b {
        color: #549dfe;
      }

      @media screen and (min-width: 600px) {
        font-size: 3rem;
      }
    }
  }
}
#logo {
  width: 100px;

  @media screen and (min-width: 600px) {
    width: 150px;
  }
}
</style>
