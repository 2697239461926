<template>
  <div id="container" ref="container"></div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

const USE_EDGE_MATERIAL = false;

export default {
  name: 'ThreeModelViewer',
  props: {
    model: {
      type: Object,
      default: null
    },
    extraXOffset: {
      type: Number,
      default: 0
    },
    extraYOffset: {
      type: Number,
      default: 0
    },
    extraZOffset: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      camera: null,
      scene: null,
      material: null,
      renderer: null,
      controls: null,
      mesh: null
    };
  },
  watch: {
    model(newVal) {
      console.log({
        newVal
      });
      if (newVal) {
        this.loadModel(newVal.model);
      }
    },
    extraXOffset(newVal) {
      console.log('xtra offset', ...this.obj.position);
      this.obj.position.set(
        newVal,
        this.obj.position.y || 0,
        this.obj.position.z || 0
      );
    },
    extraYOffset(newVal) {
      this.obj.position.set(
        this.obj.position.x || 0,
        newVal,
        this.obj.position.z || 0
      );
    },
    extraZOffset(newVal) {
      this.obj.position.set(
        this.obj.position.x || 0,
        this.obj.position.y || 0,
        newVal
      );
    }
  },
  mounted() {
    this.init();
    this.animate();
    if (this.model) this.loadModel(this.model.model);
  },
  beforeDestroy() {
    // Clean up resources
    this.controls.dispose();
    this.renderer.dispose();
  },
  methods: {
    loadModel() {
      const loader = new OBJLoader();
      if (this.obj) {
        const selectedObject = this.scene.getObjectByName(this.obj.name);
        this.scene.remove(selectedObject);
        this.animate();
      }
      loader.load(
        this.model.model,
        obj => {
          this.obj = obj;
          this.obj.name = 'object';
          // Scale and position the model
          const { scale, yOffset, xOffset, zOffset, rotate } = this.model;
          obj.scale.set(scale, scale, scale);
          obj.position.set(xOffset || 0, yOffset || 0, zOffset || 0);
          if (rotate) {
            obj.rotateX(-Math.PI / 2);
          }

          obj.children.forEach(child => {
            if (child instanceof THREE.Mesh) {
              child.castShadow = true;
              child.receiveShadow = true;
              child.material = this.material;

              if (USE_EDGE_MATERIAL) {
                // child.material = material;
                const edgesGeometry = new THREE.EdgesGeometry(child.geometry);

                // create a material for the edges
                const edgesMaterial = new THREE.LineBasicMaterial({
                  color: 0xffffff,
                  linewidth: 5,
                  side: THREE.DoubleSide // make the material twosided
                });

                // create a wireframe mesh using the edges geometry and edges material
                const wireframe = new THREE.LineSegments(
                  edgesGeometry,
                  edgesMaterial
                );

                // add the wireframe mesh to the same parent object as the mesh
                child.parent.add(wireframe);
              }
            }
          });

          // Add the model to the scene
          this.scene.add(obj);

          // Save the mesh for later use
          this.mesh = obj.children[0];
        },
        xhr => {
          console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        err => {
          console.error('An error happened', err);
        }
      );
    },
    init() {
      const { container } = this.$refs;

      console.log('ratio', container.clientWidth / container.clientHeight);

      // Create a camera
      this.camera = new THREE.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        0.1,
        1000
      );
      this.camera.position.z = 15;

      // Create a scene
      this.scene = new THREE.Scene();

      // Add lighting
      const ambientLight = new THREE.AmbientLight(0x0000ff, 0.4);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.castShadow = true;
      directionalLight.position.set(100, 100, 100);

      const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight2.castShadow = true;
      directionalLight2.position.set(-100, -100, 100);

      this.scene.add(ambientLight);
      this.scene.add(directionalLight);
      this.scene.add(directionalLight2);

      this.material = new THREE.MeshStandardMaterial({
        color: 0xf1c27d, // light brown
        specular: 0x000000,
        shininess: 0,
        shading: THREE.FlatShading,
        side: THREE.DoubleSide // make the material twosided
      });

      // Create a renderer
      this.renderer = new THREE.WebGLRenderer(container, {
        antialias: true
      });

      this.renderer.setClearColor(0x000000, 0.3);
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      this.renderer.setPixelRatio(2);
      container.appendChild(this.renderer.domElement);

      // Load the OBJ model

      // Add mouse controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.25;
      this.controls.screenSpacePanning = false;
      this.controls.minDistance = 1;
      this.controls.maxDistance = 10;
      this.controls.maxPolarAngle = Math.PI / 2;
    },
    animate() {
      requestAnimationFrame(this.animate);

      this.controls.update();

      this.renderer.render(this.scene, this.camera);
    },
    exportImage() {
      // Get the canvas element

      this.renderer.render(this.scene, this.camera);
      const canvas = this.renderer.domElement;

      // Create a data URL of the canvas contents
      const dataURL = canvas.toDataURL();

      // download file like this.
      // const a = document.createElement('a');
      // a.href = this.renderer.domElement
      //   .toDataURL()
      //   .replace('image/png', 'image/octet-stream');
      // a.download = 'canvas.png';
      // a.click();
      return dataURL;
    }
  }
};
</script>
